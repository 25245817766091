import type { JobGroup, MatejScenarios } from '@borg/types';

class RecommendedJobsRepository extends Repository<JobGroup> {
  getMany(scenario: MatejScenarios, cache?: boolean, lazy?: boolean, immediate?: boolean) {
    return this._getMany({
      url: '/jobs/recommended',
      query: { scenario },
      cache: cache ? 'always' : undefined,
      lazy,
      immediate,
    });
  }
}

export const recommendedJobsRepository = new RecommendedJobsRepository();
